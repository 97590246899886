/* barlow-latin-100-normal*/
@font-face {
  font-family: 'Barlow';
  font-style: normal;
  font-display: swap;
  font-weight: 100;
  src:
    local('Barlow Thin'),
    local('Barlow-Thin'), 
    url('./files/barlow-latin-100-normal.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('./files/barlow-latin-100-normal.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* barlow-latin-100-italic*/
@font-face {
  font-family: 'Barlow';
  font-style: italic;
  font-display: swap;
  font-weight: 100;
  src:
    local('Barlow Thin Italic'),
    local('Barlow-ThinItalic'), 
    url('./files/barlow-latin-100-italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('./files/barlow-latin-100-italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* barlow-latin-200-normal*/
@font-face {
  font-family: 'Barlow';
  font-style: normal;
  font-display: swap;
  font-weight: 200;
  src:
    local('Barlow ExtraLight'),
    local('Barlow-ExtraLight'), 
    url('./files/barlow-latin-200-normal.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('./files/barlow-latin-200-normal.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* barlow-latin-200-italic*/
@font-face {
  font-family: 'Barlow';
  font-style: italic;
  font-display: swap;
  font-weight: 200;
  src:
    local('Barlow ExtraLight Italic'),
    local('Barlow-ExtraLightItalic'), 
    url('./files/barlow-latin-200-italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('./files/barlow-latin-200-italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* barlow-latin-300-normal*/
@font-face {
  font-family: 'Barlow';
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src:
    local('Barlow Light'),
    local('Barlow-Light'), 
    url('./files/barlow-latin-300-normal.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('./files/barlow-latin-300-normal.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* barlow-latin-300-italic*/
@font-face {
  font-family: 'Barlow';
  font-style: italic;
  font-display: swap;
  font-weight: 300;
  src:
    local('Barlow Light Italic'),
    local('Barlow-LightItalic'), 
    url('./files/barlow-latin-300-italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('./files/barlow-latin-300-italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* barlow-latin-400-normal*/
@font-face {
  font-family: 'Barlow';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src:
    local('Barlow Regular'),
    local('Barlow-Regular'), 
    url('./files/barlow-latin-400-normal.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('./files/barlow-latin-400-normal.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* barlow-latin-400-italic*/
@font-face {
  font-family: 'Barlow';
  font-style: italic;
  font-display: swap;
  font-weight: 400;
  src:
    local('Barlow Italic'),
    local('Barlow-Italic'), 
    url('./files/barlow-latin-400-italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('./files/barlow-latin-400-italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* barlow-latin-500-normal*/
@font-face {
  font-family: 'Barlow';
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src:
    local('Barlow Medium'),
    local('Barlow-Medium'), 
    url('./files/barlow-latin-500-normal.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('./files/barlow-latin-500-normal.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* barlow-latin-500-italic*/
@font-face {
  font-family: 'Barlow';
  font-style: italic;
  font-display: swap;
  font-weight: 500;
  src:
    local('Barlow Medium Italic'),
    local('Barlow-MediumItalic'), 
    url('./files/barlow-latin-500-italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('./files/barlow-latin-500-italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* barlow-latin-600-normal*/
@font-face {
  font-family: 'Barlow';
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src:
    local('Barlow SemiBold'),
    local('Barlow-SemiBold'), 
    url('./files/barlow-latin-600-normal.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('./files/barlow-latin-600-normal.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* barlow-latin-600-italic*/
@font-face {
  font-family: 'Barlow';
  font-style: italic;
  font-display: swap;
  font-weight: 600;
  src:
    local('Barlow SemiBold Italic'),
    local('Barlow-SemiBoldItalic'), 
    url('./files/barlow-latin-600-italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('./files/barlow-latin-600-italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* barlow-latin-700-normal*/
@font-face {
  font-family: 'Barlow';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src:
    local('Barlow Bold'),
    local('Barlow-Bold'), 
    url('./files/barlow-latin-700-normal.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('./files/barlow-latin-700-normal.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* barlow-latin-700-italic*/
@font-face {
  font-family: 'Barlow';
  font-style: italic;
  font-display: swap;
  font-weight: 700;
  src:
    local('Barlow Bold Italic'),
    local('Barlow-BoldItalic'), 
    url('./files/barlow-latin-700-italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('./files/barlow-latin-700-italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* barlow-latin-800-normal*/
@font-face {
  font-family: 'Barlow';
  font-style: normal;
  font-display: swap;
  font-weight: 800;
  src:
    local('Barlow ExtraBold'),
    local('Barlow-ExtraBold'), 
    url('./files/barlow-latin-800-normal.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('./files/barlow-latin-800-normal.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* barlow-latin-800-italic*/
@font-face {
  font-family: 'Barlow';
  font-style: italic;
  font-display: swap;
  font-weight: 800;
  src:
    local('Barlow ExtraBold Italic'),
    local('Barlow-ExtraBoldItalic'), 
    url('./files/barlow-latin-800-italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('./files/barlow-latin-800-italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* barlow-latin-900-normal*/
@font-face {
  font-family: 'Barlow';
  font-style: normal;
  font-display: swap;
  font-weight: 900;
  src:
    local('Barlow Black'),
    local('Barlow-Black'), 
    url('./files/barlow-latin-900-normal.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('./files/barlow-latin-900-normal.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* barlow-latin-900-italic*/
@font-face {
  font-family: 'Barlow';
  font-style: italic;
  font-display: swap;
  font-weight: 900;
  src:
    local('Barlow Black Italic'),
    local('Barlow-BlackItalic'), 
    url('./files/barlow-latin-900-italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('./files/barlow-latin-900-italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
